import React from 'react';
import ContentComonents from '../components/cms';
import Layout from '../modules/Layout';

const ContentPage = ({ pageContext, location }) => {
  console.log('ContentPage', pageContext);
  const isHome = location.pathname === '/';
  return (
    <div style={{ zIndex: 5 }}>
      <Layout
        pageName={pageContext.title}
        description={pageContext.seoDescription}
        isHome={isHome}
        showNews={pageContext.showNews}
        showGoogleMaps={pageContext.showGoogleMaps}
        showContactInfo={pageContext.showContactInfo}
      >
        <ContentComonents
          components={pageContext.content}
          containsPageHeader={
            pageContext.content[0].internal.type === 'ContentfulComponentPageHeader' ||
            pageContext.content[0].internal.type === 'ContentfulComponentPageHeaderSub'
          }
        />
      </Layout>
    </div>
  );
};

export default ContentPage;
